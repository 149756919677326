@import "../../../mixins";

@mixin mediaCTAMobile {
  @media screen and (max-width: #{rem(740)}) {
    @content;
  }
}

.cta-section {
  --padding-top: #{rem(90)};
  --padding-right: #{rem(110)};
  --padding-bottom: #{rem(100)};
  --padding-left: #{rem(110)};

  @include mediaBigDesktop {
    --padding-top: #{big(90)};
    --padding-right: #{big(110)};
    --padding-bottom: #{big(100)};
    --padding-left: #{big(110)};
  }

  @include mediaDesktop {
    --padding-top: #{rem(70)};
    --padding-right: #{rem(90)};
    --padding-bottom: #{rem(80)};
    --padding-left: #{rem(90)};
  }

  @include mediaLaptop {
    --padding-top: #{rem(50)};
    --padding-right: #{rem(60)};
    --padding-bottom: #{rem(60)};
    --padding-left: #{rem(60)};
  }

  @include mediaTablet {
    --padding-top: #{rem(40)};
    --padding-right: #{rem(40)};
    --padding-bottom: #{rem(40)};
    --padding-left: #{rem(40)};
  }

  @include mediaMobile {
    --padding-top: #{rem(30)};
    --padding-right: #{rem(15)};
    --padding-bottom: #{rem(30)};
    --padding-left: #{rem(15)};
  }

  &__wrapper {
    position: relative;

    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(50);

    background: var(--cta-gradient);
    border-radius: var(--radius-common);

    @include mediaBigDesktop {
      gap: big(50);
    }

    @include mediaTablet {
      gap: rem(20);

      border-radius: var(--radius-secondary);
    }

    @include mediaCTAMobile {
      & {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: rem(25);
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 50%;
    gap: rem(45);

    @include mediaBigDesktop {
      gap: big(45);
    }

    @include mediaDesktop {
      gap: rem(36);
    }

    @include mediaLaptop {
      gap: rem(30);
    }

    @include mediaTablet {
      max-width: rem(353);
      gap: rem(25);
    }

    @include mediaCTAMobile {
      & {
        max-width: unset;
        flex-basis: unset;
        gap: rem(20);
      }
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(25);

    @include mediaBigDesktop {
      gap: big(25);
    }

    @include mediaDesktop {
      gap: rem(20);
    }

    @include mediaLaptop {
      gap: rem(15);
    }

    @include mediaCTAMobile {
      & {
        gap: rem(10);
      }
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
  }

  &__list {
    margin: 0;
    margin-top: rem(5);

    padding: 0;
    padding-top: rem(20);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(25);

    list-style: none;

    @include mediaBigDesktop {
      margin-top: big(5);

      padding-top: big(20);
      gap: big(25);
    }

    @include mediaDesktop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rem(20);
    }

    @include mediaLaptop {
      padding-top: rem(15);
      gap: rem(15);
    }

    @include mediaCTAMobile {
      & {
        padding-top: rem(10);
        gap: rem(10);
      }
    }

    @include mediaMobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    & li {
      padding-right: rem(30);
      width: 100%;
      max-width: rem(402);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(20);

      color: var(--text-dark-primary);
      font: var(--font-body-L-m);

      @include mediaBigDesktop {
        padding-right: big(30);
        max-width: big(402);
        gap: big(20);
      }

      @include mediaLaptop {
        gap: rem(15);
      }

      @include mediaMobile {
        gap: rem(10);
      }

      &::before {
        content: "";

        position: relative;
        top: 3px;

        width: rem(30);
        height: rem(30);
        display: flex;
        flex-shrink: 0;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cg clip-path='url(%23clip0_3663_35080)'%3E%3Cellipse cx='15' cy='15' rx='9' ry='7.5' fill='white'/%3E%3Cpath d='M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19971 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649926 12.0006 -0.290551 15.0166 0.288227 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16393 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3335C29.1203 20.8668 30 17.9667 30 15C29.9958 11.023 28.4141 7.21017 25.602 4.39804C22.7898 1.5859 18.977 0.00419974 15 0ZM21.5856 12.3548L13.5087 20.4317C13.4015 20.539 13.2742 20.6241 13.1342 20.6822C12.9941 20.7402 12.8439 20.7701 12.6923 20.7701C12.5407 20.7701 12.3905 20.7402 12.2505 20.6822C12.1104 20.6241 11.9831 20.539 11.876 20.4317L8.41443 16.9702C8.19792 16.7537 8.07629 16.46 8.07629 16.1538C8.07629 15.8477 8.19792 15.554 8.41443 15.3375C8.63094 15.121 8.92459 14.9994 9.23077 14.9994C9.53696 14.9994 9.83061 15.121 10.0471 15.3375L12.6923 17.9841L19.9529 10.7221C20.0601 10.6149 20.1874 10.5299 20.3274 10.4719C20.4675 10.4138 20.6176 10.384 20.7692 10.384C20.9208 10.384 21.071 10.4138 21.211 10.4719C21.3511 10.5299 21.4784 10.6149 21.5856 10.7221C21.6928 10.8293 21.7778 10.9566 21.8358 11.0967C21.8939 11.2367 21.9237 11.3869 21.9237 11.5385C21.9237 11.6901 21.8939 11.8402 21.8358 11.9803C21.7778 12.1203 21.6928 12.2476 21.5856 12.3548Z' fill='%2355ABD4'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3663_35080'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include mediaBigDesktop {
          width: big(30);
          height: big(30);
        }

        @include mediaDesktop {
          top: big(3);

          width: rem(24);
          height: rem(24);
        }

        @include mediaLaptop {
          width: rem(20);
          height: rem(20);
        }
      }
    }
  }

  &__description {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-body-L-r);

    @include mediaTablet {
      font: var(--font-body-M-r);
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(15);
    }

    @include mediaTablet {
      gap: rem(10);
    }

    @include media(#{rem(500)}) {
      flex-direction: column;
    }

    & > .button {
      @include mediaCTAMobile {
        & {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }

  &__social {
    &.button {
      --button-bg-color: var(--bg-white);
      --button-bg-color-hover: #25A0E5;
      --button-icon-color: #25A0E5;
      --button-icon-color-hover: var(--text-light-primary);
      --button-text-color: var(--text-dark-primary);
      --button-text-color-hover: var(--text-light-primary);

      @include hover {
        & .icon {
          fill: var(--button-icon-color-hover);
        }
      }
    }

    & .icon {
      width: rem(30);
      height: rem(30);

      @include mediaBigDesktop {
        width: big(30);
        height: big(30);
      }

      @include mediaDesktop {
        width: rem(24);
        height: rem(24);
      }

      @include mediaLaptop {
        width: rem(20);
        height: rem(20);
      }
    }
  }
}

.cta-person {
  position: absolute;
  top: var(--padding-top);
  right: var(--padding-right);
  z-index: 0;

  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;

  @include mediaTablet {
    flex-direction: column;
  }
  
  @include mediaCTAMobile {
    position: static;
  }

  &__image {
    position: relative;
    z-index: 0;

    width: rem(300);
    height: rem(300);
    flex-shrink: 0;

    border-radius: var(--radius-infinity);
    overflow: hidden;

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      width: big(300);
      height: big(300);
    }

    @include mediaDesktop {
      width: rem(256);
      height: rem(256);
    }

    @include mediaLaptop {
      width: rem(223);
      height: rem(223);
    }

    @include mediaTablet {
      width: rem(167);
      height: rem(167);
    }

    @include mediaCTAMobile() {
      width: 75.7%;
      height: auto;
      aspect-ratio: 1/1;

      @supports not (aspect-ratio: 1/1) {
        width: rem(167);
        height: rem(167);
      }
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    position: relative;
    right: rem(-25);
    z-index: 1;

    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    @include mediaBigDesktop {
      right: big(-25);
    }

    @include mediaTablet {
      top: rem(-25);
      right: 0;

      align-items: center;
    }

    @include mediaCTAMobile {
      top: 0;
      margin-top: rem(-25);
    }
  }

  &__name {
    position: relative;

    padding: 0 rem(20);
    height: rem(40);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-dark-primary);
    font: 600 rem(20)/1.4 var(--font-primary);

    background-color: var(--bg-white);
    border-radius: rem(7);

    @include mediaBigDesktop {
      padding: 0 big(20);
      height: big(40);

      font-size: big(20);

      border-radius: big(7);
    }

    @include mediaDesktop {
      font-size: rem(16);
    }

    @include mediaLaptop {
      font-size: rem(14);
    }

    @include mediaTablet {
      padding: 0 rem(15);
    }

    @include mediaCTAMobile {
      height: rem(34);
    }
  }

  &__position {
    position: relative;
    top: rem(-1);

    padding: 0 rem(20);
    height: rem(35);
    display: flex;
    align-items: flex-start;
    justify-content: center;

    color: var(--text-dark-secondary);
    font: 400 rem(18)/1.55 var(--font-primary);

    background-color: var(--bg-white);
    border-radius: rem(7);

    @include mediaBigDesktop {
      top: big(-1);

      padding: 0 big(20);
      height: big(35);

      font-size: big(18);

      border-radius: big(7);
    }

    @include mediaDesktop {
      font-size: rem(14);
    }

    @include mediaLaptop {
      font-size: rem(14);
    }

    @include mediaTablet {
      padding: 0 rem(15);
    }

    @include mediaCTAMobile {
      height: rem(28);
    }

    &::before {
      content: "";

      position: absolute;
      top: rem(-8);
      right: 0;
      left: 0;
      z-index: -1;

      width: auto;
      height: rem(14);

      background-color: var(--bg-white);

      @include mediaBigDesktop {
        top: big(-8);

        height: big(14);
      }
    }
  }
}

.cta-image {
  position: absolute;
  top: 0;
  right: 0;

  width: 50%;
  max-width: rem(932);
  height: 100%;

  @include mediaBigDesktop {
    max-width: big(932);
  }

  @include mediaLaptop() {
    right: rem(13);

    width: 55%;
    max-width: unset;
  }

  @include mediaTablet {
    top: unset;
    right: 0;
    bottom: 0;

    min-width: rem(376);
    height: auto;
    max-height: 100%;
    aspect-ratio: 376/207;

    @supports not (aspect-ratio: 376/207) {
      height: rem(207);
    }
  }

  @include mediaCTAMobile {
    display: none;
  }

  & img,
  & picture {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.cta-quiz {
  position: absolute;
  right: var(--padding-right);
  bottom: 0;

  width: rem(752);
  height: rem(607);

  @include mediaBigDesktop {
    width: big(752);
    height: big(607);
  }

  @include mediaDesktop {
    right: 0;

    width: rem(600);
    height: rem(485);
  }

  @include mediaTablet {
    display: none;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
    user-select: none;

    & picture,
    & img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  &__balloon {
    --font-banner-adv-background: var(--bg-white);
    --front-banner-adv-color: var(--accent-primary-default);

    position: absolute;
    top: rem(308);
    left: rem(55);
    margin: 0;

    padding: rem(25);
    width: rem(168);
    height: rem(168);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: var(--front-banner-adv-color);
    font: 800 rem(32)/1.5 var(--font-primary);
    text-align: center;

    background-color: var(--font-banner-adv-background);
    border-radius: var(--radius-infinity);

    @include mediaBigDesktop {
      top: big(308);
      left: big(55);

      padding: big(25);
      width: rem(168);
      height: rem(168);

      font-size: big(32);
    }

    @include mediaDesktop {
      top: rem(294);
      left: rem(47);

      width: rem(130);
      height: rem(130);

      font-size: rem(24);
    }

    & > span {
      font-size: rem(22);
      font-weight: 600;

      @include mediaBigDesktop {
        font-size: big(22);
      }

      @include mediaDesktop {
        font-size: rem(16);
      }

      @include mediaLaptop {
        font-size: rem(14);
      }
    }
  }
}
